<!--
 * @Date: 2022-04-28 13:58:48
 * @descriotion:
-->
<template>
  <div class="map">
    <a-input-search
      id="tipinput"
      v-model="searchValue"
      :placeholder="searchPlaceholder"
      style="width: 200px;"
      @search="getSearchCallback"
    />
    <div id="mapBox" class="container" />
  </div>

</template>
<script>
import AMapLoader from '@amap/amap-jsapi-loader'
export default {
  name: 'Map',
  props: {
    radius: {
      type: Number,
      default: 0
    },
    lngint: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      map: null,
      searchValue: '',
      searchPlaceholder: '请输入地址',
      autocomplete: null,
      placeSearch: null,
      marker: null,
      AMap: null,
      circle: null,
      lnglat: null,
      key: 'c64d84e9d056f8ab42c0dbdc151d36cb'
    }
  },
  watch: {
    lngint: {
      immediate: true,
      handler: function(val) {
        if (val.length < 2) {
          return
        }
        if (this.map) {
          this.lnglat = val
          this.addMarker(val)
          this.addCircle(val)
          this.map.setCenter(val)
        }
      }
    },
    radius: {
      immediate: true,
      handler(val) {
        if (this.lnglat) {
          this.map.remove(this.circle)
          this.addCircle(this.lnglat)
          // this.addMarker(this.lnglat)
        }
      }
    }
  },
  created() {

  },
  async mounted() {
    this.lnglat = this.lngint
    this.initMap()
  },
  methods: {
    detectZoom() {
      var ratio = 0
      var screen = window.screen
      var ua = navigator.userAgent.toLowerCase()
      if (window.devicePixelRatio !== undefined) {
        ratio = window.devicePixelRatio
      } else if (~ua.indexOf('msie')) {
        if (screen.deviceXDPI && screen.logicalXDPI) {
          ratio = screen.deviceXDPI / screen.logicalXDPI
        }
      } else if (window.outerWidth !== undefined && window.innerWidth !== undefined) {
        ratio = window.outerWidth / window.innerWidth
      }

      if (ratio) {
        ratio = Math.round(ratio * 100)
      }
      return ratio
    },
    addCircle(lnglat) {
      this.circle = new this.AMap.Circle({
        center: lnglat, // 圆心位置
        radius: this.radius, // 半径
        strokeColor: '#F33', // 线颜色
        strokeOpacity: 1, // 线透明度.
        strokeWeight: 1, // 线粗细度
        fillColor: '#ee2200', // 填充颜色
        fillOpacity: 0.35 // 填充透明度
      })
      this.map.add(this.circle)
      this.circle.on('click', (ev) => {
        console.log(ev)
        if (this.marker) { this.map.clearMap() }
        this.addMarker(ev.lnglat)
        this.addCircle(ev.lnglat)
        this.$emit('map', ev.lnglat.getLng(), ev.lnglat.getLat())
      })
    },
    addMarker(lnglat) {
      var icon = new this.AMap.Icon({
        size: new this.AMap.Size(20, 25), // 图标尺寸
        image: '//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png', // Icon的图像
        imageSize: new this.AMap.Size(20, 25)
      })
      this.marker = new this.AMap.Marker({
        icon: icon,
        position: lnglat,
        offset: new this.AMap.Pixel(-10, -25),
        zoom: 16
      })
      this.map.add(this.marker)
    },
    initMap() {
      AMapLoader.load({
        key: this.key, // 申请好的Web端开发者Key，首次调用 load 时必填
        plugins: ['AMap.ToolBar', 'AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Geocoder']
      }).then((AMap) => {
        this.AMap = AMap
        this.map = new AMap.Map('mapBox', { // 设置地图容器id
          viewMode: '2D', // 是否为2D地图模式
          zoom: 16, // 初始化地图级别// 初始化地图中心点位置
          zooms: [2, 22]
        })
        this.map.addControl(new AMap.ToolBar({ liteStyle: true, position: 'RB' }))
        const auto = new AMap.Autocomplete({
          input: 'tipinput'
        })

        this.placeSearch = new AMap.PlaceSearch({})
        AMap.event.addListener(auto, 'select', this.getSearchCallback)
        if (this.lnglat.length > 0) {
          this.addMarker(this.lnglat)
          this.addCircle(this.lnglat)
          this.map.setCenter(this.lnglat)
        }
        this.map.on('click', (ev) => {
          this.lnglat = ev.lnglat
          this.map.setCenter(this.lnglat)
          if (this.marker) { this.map.clearMap() }
          this.addMarker(this.lnglat)
          this.addCircle(this.lnglat)
          var geo = new AMap.Geocoder({ radius: this.radius, extensions: 'all' // 返回地址描述以及附近兴趣点和道路信息，默认“base”
          })
          geo.getAddress(this.lnglat, (e, result) => {
            console.log(result)
            this.$emit('map', ev.lnglat.getLng(), ev.lnglat.getLat(), result)
          })
        })
      }).catch(e => {
        console.log(e)
      })
    },
    getSearchCallback(e) {
      if (e.poi) {
        this.searchValue = e.poi.name
        this.placeSearch.setCity(e.poi.adcode)
      }
      this.placeSearch.search(this.searchValue, (e, result) => {
        if (e === 'complete' || result.info === 'OK') {
          this.map.setCenter(result.poiList.pois[0].location)
          if (this.marker) { this.map.clearMap() }
          console.log(result)
          const location = result.poiList.pois[0].location
          console.log(location.getLng(), location.getLat())
          this.lnglat = [location.getLng(), location.getLat()]
          this.addMarker(location)
          this.addCircle(location)
          this.$emit('map', location.getLng(), location.getLat(), result.poiList.pois[0])
          this.$emit('search', this.searchValue)
        } else {
          this.$message.error('查询错误，请重新输入')
        }
      })
    },
    clearMap() {
      this.searchValue = ''
      this.map.clearMap()
    }
  }
}
</script>
<style lang="less" scoped>
.map{
  z-index: 9999;
  width:100%;
  height:100%;
  position:relative;
  #mapBox{
    width:100%;
    min-height:100px;
    height:100%;
  }
  .ant-input-affix-wrapper{
    position:absolute;
    top:20px;
    left:20px;
    z-index: 999;
  }
}

</style>
