var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "HolidayReminder" },
    [
      _c(
        "AlertTips",
        [
          _c("template", { slot: "content" }, [
            _c("p", [
              _vm._v(
                "1.员工关注公众号并绑定小程序，当余额变动可接收微信提醒通知。"
              ),
            ]),
          ]),
        ],
        2
      ),
      _c("BlockTitle", {
        staticClass: "msg-title",
        attrs: { title: "假期提醒", "is-bold": true },
      }),
      _c(
        "a-table",
        {
          attrs: {
            columns: _vm.columns,
            "data-source": _vm.dataSource,
            "row-key": "tenantConfigId",
            pagination: false,
          },
          scopedSlots: _vm._u([
            {
              key: "configContent",
              fn: function (text, record) {
                return _c(
                  "span",
                  {},
                  [
                    _c("a-switch", {
                      attrs: {
                        "default-checked": Boolean(
                          JSON.parse(record.configContent)
                        ),
                        size: "small",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.onChange(
                            record.configContent,
                            record.configItemName
                          )
                        },
                      },
                    }),
                  ],
                  1
                )
              },
            },
          ]),
        },
        [
          _c("span", { attrs: { slot: "reminders" }, slot: "reminders" }, [
            _c("span", [_vm._v("余额变动")]),
          ]),
          _c(
            "span",
            {
              attrs: { slot: "reminderDescription" },
              slot: "reminderDescription",
            },
            [
              _c("span", [
                _vm._v("当假期余额更新，即发送提醒。包括发放、修改、扣减余额"),
              ]),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }