var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card-rule", attrs: { id: "cardModal" } },
    [
      _c(
        "div",
        { staticClass: "card-main" },
        [
          _c(
            "AlertTips",
            { on: { look: _vm.lookTips } },
            [
              _c("template", { slot: "content" }, [
                _c("p", [_vm._v("1、补卡规则，设置员工每月可补卡的次数。")]),
                _c("p", [
                  _vm._v(
                    "2、设置后，当员工发起补卡审批，超过次数将不允许提交补卡申请。"
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    "3、可设置多个不同规则，设置后，前往【考勤组】把不同规则关联到各自考勤组。"
                  ),
                ]),
              ]),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "addrulebtn" },
            [
              _c(
                "a-button",
                {
                  attrs: { type: "primary", prefix: "add-one" },
                  on: { click: _vm.addCard },
                },
                [_vm._v(" 新增补卡规则 ")]
              ),
            ],
            1
          ),
          _c("custom-table", {
            attrs: {
              id: "table",
              columns: _vm.columns,
              "tabs-height": 36,
              "data-source": _vm.dataSource,
              scroll: {
                y: _vm.tableHeight,
              },
              "row-key": "appendPunchRuleId",
              pagination: false,
            },
            scopedSlots: _vm._u([
              {
                key: "maxTimes",
                fn: function (ref) {
                  var scope = ref.scope
                  return [
                    _c("span", [
                      _vm._v(_vm._s(scope.record.maxTimes) + " 次/月"),
                    ]),
                  ]
                },
              },
              {
                key: "appendPunchLimitType",
                fn: function (ref) {
                  var scope = ref.scope
                  return [
                    _c("div", [
                      _vm._v(
                        _vm._s(
                          _vm.getCodeContent(
                            scope.record.appendPunchLimitType,
                            _vm.appendPunchLimitType
                          )
                        )
                      ),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: scope.record.appendPunchLimitType === "2",
                              expression:
                                "scope.record.appendPunchLimitType === '2'",
                            },
                          ],
                        },
                        [
                          _vm._v(
                            "，第" +
                              _vm._s(scope.record.nextMonthMaxDay) +
                              "天内可补卡"
                          ),
                        ]
                      ),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: scope.record.appendPunchLimitType === "4",
                              expression:
                                "scope.record.appendPunchLimitType === '4'",
                            },
                          ],
                        },
                        [
                          _vm._v(
                            "起的" +
                              _vm._s(scope.record.missingDayMaxDay) +
                              "天内可补卡"
                          ),
                        ]
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "operation",
                fn: function (ref) {
                  var scope = ref.scope
                  return [
                    _c("div", { staticClass: "flex-align-center" }, [
                      _c(
                        "div",
                        { staticClass: "more-handle-btn-box" },
                        [
                          _c(
                            "a-button",
                            {
                              attrs: { type: "link" },
                              on: {
                                click: function ($event) {
                                  return _vm.edit(scope.record)
                                },
                              },
                            },
                            [_vm._v(" 编辑 ")]
                          ),
                          _c(
                            "a-button",
                            {
                              staticClass: "delete",
                              attrs: { type: "link" },
                              on: {
                                click: function ($event) {
                                  return _vm.deleted(scope.record)
                                },
                              },
                            },
                            [_vm._v(" 删除 ")]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "Modal",
        {
          attrs: {
            closable: true,
            visible: _vm.cardVisible,
            "mask-closable": false,
            size: "normal",
            title: _vm.type === "add" ? "新增补卡规则" : "编辑补卡规则",
          },
          on: { cancel: _vm.onClose, ok: _vm.handleDelShift },
        },
        [
          _c(
            "div",
            { staticClass: "card-content" },
            [
              _c(
                "a-form-model",
                {
                  ref: "cardForm",
                  attrs: {
                    model: _vm.cardForm,
                    rules: _vm.rules,
                    "label-col": _vm.labelCol,
                    "wrapper-col": _vm.wrapperCol,
                  },
                },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "规则名称", prop: "ruleName" } },
                    [
                      _c("a-input", {
                        staticStyle: { width: "100%" },
                        attrs: { placeholder: "比如产品部的补卡规则" },
                        model: {
                          value: _vm.cardForm.ruleName,
                          callback: function ($$v) {
                            _vm.$set(_vm.cardForm, "ruleName", $$v)
                          },
                          expression: "cardForm.ruleName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "每位员工每个月最多补卡",
                        prop: "maxTimes",
                      },
                    },
                    [
                      _c("CbNumber", {
                        attrs: {
                          placeholder: "0",
                          max: 99,
                          width: "100%",
                          "max-length": 2,
                          suffix: "次",
                        },
                        model: {
                          value: _vm.cardForm.maxTimes,
                          callback: function ($$v) {
                            _vm.$set(_vm.cardForm, "maxTimes", $$v)
                          },
                          expression: "cardForm.maxTimes",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "补卡时间限制",
                        prop: "appendPunchLimitType",
                      },
                    },
                    [
                      _c(
                        "a-select",
                        {
                          staticStyle: { width: "72%", "margin-right": "20px" },
                          attrs: { placeholder: "请选择补卡时间限制" },
                          model: {
                            value: _vm.cardForm.appendPunchLimitType,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.cardForm,
                                "appendPunchLimitType",
                                $$v
                              )
                            },
                            expression: "cardForm.appendPunchLimitType",
                          },
                        },
                        _vm._l(_vm.appendPunchLimitType, function (item) {
                          return _c("a-select-option", { key: item.codeKey }, [
                            _vm._v(" " + _vm._s(item.content) + " "),
                          ])
                        }),
                        1
                      ),
                      _vm.cardForm.appendPunchLimitType !== "4" &&
                      _vm.cardForm.appendPunchLimitType !== "2"
                        ? _c("span", [_vm._v("内可补卡")])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm.cardForm.appendPunchLimitType === "2"
                    ? _c(
                        "a-form-model-item",
                        { attrs: { label: " ", prop: "nextMonthMaxDay" } },
                        [
                          _vm._v(" 第 "),
                          _c(
                            "a-select",
                            {
                              staticStyle: { width: "80px", margin: "0 10px" },
                              model: {
                                value: _vm.cardForm.nextMonthMaxDay,
                                callback: function ($$v) {
                                  _vm.$set(_vm.cardForm, "nextMonthMaxDay", $$v)
                                },
                                expression: "cardForm.nextMonthMaxDay",
                              },
                            },
                            _vm._l(28, function (index) {
                              return _c(
                                "a-select-option",
                                { key: index, attrs: { value: index } },
                                [_vm._v(_vm._s(index) + "天")]
                              )
                            }),
                            1
                          ),
                          _vm._v(" 内可补卡 "),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.cardForm.appendPunchLimitType === "4"
                    ? _c(
                        "a-form-model-item",
                        { attrs: { label: " ", prop: "missingDayMaxDay" } },
                        [
                          _vm._v(" 起的 "),
                          _c(
                            "a-select",
                            {
                              staticStyle: { width: "80px", margin: "0 10px" },
                              model: {
                                value: _vm.cardForm.missingDayMaxDay,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.cardForm,
                                    "missingDayMaxDay",
                                    $$v
                                  )
                                },
                                expression: "cardForm.missingDayMaxDay",
                              },
                            },
                            _vm._l(31, function (index) {
                              return _c(
                                "a-select-option",
                                { key: index, attrs: { value: index } },
                                [_vm._v(_vm._s(index) + "天")]
                              )
                            }),
                            1
                          ),
                          _vm._v(" 内可补卡 "),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }