var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "address", attrs: { id: "address" } },
    [
      _c(
        "appForm",
        {
          attrs: { "to-list": _vm.formList, "search-placeholder": "请搜索" },
          on: { getSearchCallback: _vm.getSearchCallback },
        },
        [
          _c(
            "template",
            { slot: "customButton" },
            [
              _c(
                "a-button",
                {
                  attrs: { type: "primary", prefix: "add-one" },
                  on: { click: _vm.addAddress },
                },
                [_vm._v(" 新增打卡地址 ")]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("custom-table", {
        attrs: {
          id: "table",
          columns: _vm.columns,
          "data-source": _vm.dataSource,
          pagination: false,
          "tabs-height": 36,
          "row-key": "clockLocationId",
        },
        on: { change: _vm.loadDataList },
        scopedSlots: _vm._u([
          {
            key: "radius",
            fn: function (ref) {
              var scope = ref.scope
              return [_c("span", [_vm._v(_vm._s(scope.record.radius) + " 米")])]
            },
          },
          {
            key: "addressType",
            fn: function (ref) {
              var scope = ref.scope
              return [
                _c("span", {
                  domProps: {
                    innerHTML: _vm._s(
                      scope.record.outsideFlag === 0 ? "上下班打卡" : "外勤打卡"
                    ),
                  },
                }),
              ]
            },
          },
          {
            key: "operation",
            fn: function (ref) {
              var scope = ref.scope
              return [
                [
                  _c(
                    "div",
                    { staticClass: "more-handle-btn-box" },
                    [
                      _c(
                        "a-button",
                        {
                          attrs: { type: "link" },
                          on: {
                            click: function ($event) {
                              return _vm.edit(scope.record)
                            },
                          },
                        },
                        [_vm._v(" 编辑 ")]
                      ),
                      _c(
                        "a-button",
                        {
                          staticClass: "delete",
                          attrs: { type: "link", danger: "" },
                          on: {
                            click: function ($event) {
                              return _vm.delet(scope.record)
                            },
                          },
                        },
                        [_vm._v(" 删除 ")]
                      ),
                    ],
                    1
                  ),
                ],
              ]
            },
          },
        ]),
      }),
      _c(
        "Modal",
        {
          attrs: {
            title: _vm.title,
            size: "normal",
            "overflow-auto": "",
            closable: true,
            visible: _vm.dialogFormVisible,
            "mask-closable": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
            cancel: _vm.cancel,
            ok: _vm.ok,
          },
        },
        [
          _c(
            "a-form-model",
            {
              ref: "addressForm",
              attrs: {
                model: _vm.form,
                "label-col": _vm.labelCol,
                "wrapper-col": _vm.wrapperCol,
                rules: _vm.rules,
              },
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    width: "100%",
                    height: "250px",
                    margin: "0 auto 20px",
                  },
                },
                [
                  _c("Map", {
                    ref: "Map",
                    attrs: { lngint: _vm.lngint, radius: parseInt(_vm.radius) },
                    on: { map: _vm.onMap, search: _vm.onSearch },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "打卡地址名称", prop: "locationName" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入打卡地址名称" },
                    model: {
                      value: _vm.form.locationName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "locationName", $$v)
                      },
                      expression: "form.locationName",
                    },
                  }),
                  _c("span", [
                    _vm._v("打卡地址名称默认是您搜索的地点名称，您可以修改"),
                  ]),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "实际打卡地址" } },
                [
                  _c("a-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.gpsName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "gpsName", $$v)
                      },
                      expression: "form.gpsName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  staticClass: "modelItem",
                  attrs: { label: "打卡范围", prop: "radius" },
                },
                [
                  _c("a-select", {
                    attrs: { options: _vm.rangePunch, "default-value": 100 },
                    on: { change: _vm.selectChange },
                    model: {
                      value: _vm.form.radius,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "radius", $$v)
                      },
                      expression: "form.radius",
                    },
                  }),
                  _c(
                    "CbNumber",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.flag,
                          expression: "flag",
                        },
                      ],
                      staticClass: "select-input",
                      attrs: { maxlength: 5 },
                      model: {
                        value: _vm.radius,
                        callback: function ($$v) {
                          _vm.radius = $$v
                        },
                        expression: "radius",
                      },
                    },
                    [_c("template", { slot: "addonAfter" }, [_vm._v("米")])],
                    2
                  ),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "打卡地址类别", prop: "outsideFlag" } },
                [
                  _c("a-radio-group", {
                    attrs: { options: _vm.outsideFlag },
                    model: {
                      value: _vm.form.outsideFlag,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "outsideFlag", $$v)
                      },
                      expression: "form.outsideFlag ",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }