var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "attendance", attrs: { id: "attendanceGroup" } },
    [
      _vm.formVisible
        ? _c(
            "div",
            { staticClass: "attendanceList" },
            [
              _c("Alert", {
                staticClass: "alert",
                attrs: { closable: "", "show-icon": "" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "content",
                      fn: function () {
                        return [
                          _c("p", [
                            _vm._v(
                              "1.请先在【设置】中设置“考勤项”、“打卡方式”、“规则”、“班次”、“排班日历”相关项。"
                            ),
                          ]),
                          _c("p", [
                            _vm._v(
                              "2.提示：有" +
                                _vm._s(
                                  _vm.dataPrompt.filter(function (item) {
                                    return !item.ignoreType
                                  }).length
                                ) +
                                "人暂无考勤组 "
                            ),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.dataPrompt.filter(function (item) {
                                        return item.ignoreType
                                      }).length > 0,
                                    expression:
                                      "dataPrompt.filter(item => item.ignoreType).length>0",
                                  },
                                ],
                              },
                              [
                                _vm._v(
                                  "、有" +
                                    _vm._s(
                                      _vm.dataPrompt.filter(function (item) {
                                        return item.ignoreType
                                      }).length
                                    ) +
                                    "人不参与考勤组管理"
                                ),
                              ]
                            ),
                            _vm._v("，前往"),
                            _c(
                              "span",
                              {
                                staticClass: "look",
                                on: { click: _vm.lookPer },
                              },
                              [
                                _vm._v("查看"),
                                _c("svg", { staticClass: "iconpark-icon" }, [
                                  _c("use", { attrs: { href: "#left" } }),
                                ]),
                              ]
                            ),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  4236684675
                ),
              }),
              _c(
                "AlertTips",
                [
                  _c("template", { slot: "content" }, [
                    _c("p", [
                      _vm._v(
                        "1.考勤组，即按部门和员工分组，并为每个组设置不同的出勤日等。"
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "2.支持设置多个考勤组，灵活组合员工的出勤方式，如双休、单休、大小周或排班等。"
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "3.不考勤方案，适用于企业中不需要考勤的人员，即不需要打卡或记录请假等，如企业高层。"
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "4.不打卡方案，适用于企业中不需要打卡，但需要记录请假的人员 ，如长期外勤人员。"
                      ),
                    ]),
                  ]),
                ],
                2
              ),
              _c(
                "appForm",
                {
                  attrs: {
                    "to-list": _vm.formList,
                    "search-placeholder": "请搜索",
                  },
                  on: { getSearchCallback: _vm.getSearchCallback },
                },
                [
                  _c(
                    "template",
                    { slot: "customButton" },
                    [
                      _c(
                        "a-button",
                        {
                          attrs: { type: "primary", prefix: "add-one" },
                          on: { click: _vm.addGroup },
                        },
                        [_vm._v(" 新增考勤组 ")]
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
              _c("custom-table", {
                attrs: {
                  id: "table",
                  columns: _vm.columns,
                  "data-source": _vm.dataSource,
                  pagination: false,
                  "row-key": "attendanceGroupId",
                },
                on: { change: _vm.loadDataList },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "workCycleSequenceStr",
                      fn: function (ref) {
                        var scope = ref.scope
                        return [
                          _c("span", {
                            attrs: {
                              title:
                                scope.record.shiftScheduling
                                  .workCycleSequenceStr,
                            },
                            domProps: {
                              innerHTML: _vm._s(
                                scope.record.shiftScheduling
                                  .workCycleSequenceStr
                              ),
                            },
                          }),
                        ]
                      },
                    },
                    {
                      key: "shiftPunchRuleStr",
                      fn: function (ref) {
                        var scope = ref.scope
                        return [
                          _c("span", {
                            domProps: {
                              innerHTML: _vm._s(
                                scope.record.shiftScheduling.shiftPunchRuleStr
                              ),
                            },
                          }),
                        ]
                      },
                    },
                    {
                      key: "shiftSchedulingId",
                      fn: function (ref) {
                        var scope = ref.scope
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                scope.record.attendanceGroupType === "1"
                                  ? "无需打卡"
                                  : "需打卡"
                              )
                            ),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "morerule",
                      fn: function (ref) {
                        var scope = ref.scope
                        return [
                          _c("span", [
                            _vm._v(
                              "加班规则-" +
                                _vm._s(scope.record.overTimeRuleName)
                            ),
                          ]),
                          _c("br"),
                          _c("span", [
                            _vm._v(
                              "外勤规则-" + _vm._s(scope.record.outSideRuleName)
                            ),
                          ]),
                          _c("br"),
                          _c("span", [
                            _vm._v(
                              "补卡规则-" +
                                _vm._s(scope.record.appendPunchRuleName)
                            ),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "operation",
                      fn: function (ref) {
                        var scope = ref.scope
                        return [
                          [
                            _c(
                              "div",
                              { staticClass: "operation-btn-box" },
                              [
                                _c(
                                  "a-button",
                                  {
                                    attrs: { type: "link" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.edit(scope.record)
                                      },
                                    },
                                  },
                                  [_vm._v(" 编辑 ")]
                                ),
                              ],
                              1
                            ),
                          ],
                          _c(
                            "a-popover",
                            {
                              attrs: { placement: "bottom", trigger: "hover" },
                            },
                            [
                              _c("template", { slot: "content" }, [
                                _c(
                                  "div",
                                  { staticClass: "more-handle-btn" },
                                  [
                                    _c(
                                      "a-button",
                                      {
                                        attrs: { type: "link" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.copying(scope.record)
                                          },
                                        },
                                      },
                                      [_vm._v(" 复制 ")]
                                    ),
                                    _c(
                                      "a-button",
                                      {
                                        staticClass: "delete",
                                        attrs: { type: "link" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.delet(scope.record)
                                          },
                                        },
                                      },
                                      [_vm._v(" 删除 ")]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c(
                                "div",
                                { staticClass: "more-handle more-handle-box" },
                                [_vm._v(" ··· ")]
                              ),
                            ],
                            2
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3012045397
                ),
              }),
            ],
            1
          )
        : _c("AddAttendance", {
            attrs: {
              type: _vm.type,
              "attendance-group-id": _vm.attendanceGroupId,
            },
            on: { cancle: _vm.cancle },
          }),
      _c("CbIgnoreModal", {
        attrs: {
          visible: _vm.visible,
          placeholder: "请选择考勤组",
          title: "无排班人员",
          "replace-fields": {
            label: "attendanceGroupName",
            value: "attendanceGroupId",
          },
          "replace-group-key": "attendanceGroupId",
          "replace-ignore-type-key": "ignoreType",
          "data-prompt": _vm.dataPrompt,
          "select-list": _vm.dataSource,
        },
        on: { cancel: _vm.cancel, setPerson: _vm.handleOk },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }