var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "VacationPlan beauty-scroll",
      attrs: { id: "vacationPlan" },
    },
    [
      _c(
        "AlertTips",
        [
          _c("template", { slot: "content" }, [
            _c("p", [
              _vm._v(
                "1.您可以选择已启用的假期类型，设置不同假期方案规则，并关联适用人员。"
              ),
            ]),
            _c("p", [
              _vm._v(
                "2.设置后，可以在【假期】→【余额】管理年假、调休假、带薪假期等假期余额。"
              ),
            ]),
            _c("p", [
              _vm._v(
                "3.有关假期扣款规则，您可以在【薪酬】→【设置】→【计薪规则】中进行设置。"
              ),
            ]),
          ]),
        ],
        2
      ),
      _c(
        "appForm",
        {
          attrs: { "to-list": _vm.formList, "search-placeholder": "请搜索" },
          on: { getSearchCallback: _vm.getSearchCallback },
        },
        [
          _c("template", { slot: "customButton" }, [
            _c(
              "div",
              { ref: "customButton", staticClass: "custom-button" },
              [
                _c(
                  "a-dropdown",
                  {
                    attrs: {
                      "get-popup-container": function () {
                        return _vm.$refs.customButton
                      },
                    },
                  },
                  [
                    _c("a-button", { attrs: { suffix: "left" } }, [
                      _vm._v(" " + _vm._s(_vm.vacationName) + " "),
                    ]),
                    _c(
                      "a-menu",
                      { attrs: { slot: "overlay" }, slot: "overlay" },
                      _vm._l(_vm.vacationTypeList, function (vacation) {
                        return _c(
                          "a-menu-item",
                          {
                            key: vacation.vacationTypeId,
                            on: {
                              click: function ($event) {
                                return _vm.initData(vacation)
                              },
                            },
                          },
                          [_c("a", [_vm._v(_vm._s(vacation.vacationTypeName))])]
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "a-button",
                  {
                    attrs: { type: "primary", prefix: "add-one" },
                    on: { click: _vm.addVacation },
                  },
                  [_vm._v(" 新增假期方案 ")]
                ),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c("custom-table", {
        attrs: {
          id: "table",
          columns: _vm.columns,
          "data-source": _vm.dataSource,
          "row-key": "vacationRuleId",
          pagination: false,
        },
        on: { change: _vm.loadDataList },
        scopedSlots: _vm._u([
          {
            key: "ruleName",
            fn: function (ref) {
              var scope = ref.scope
              return [
                _c("span", {
                  staticClass: "ellipsis",
                  attrs: { title: scope.record.ruleName },
                  domProps: { innerHTML: _vm._s(scope.record.ruleName) },
                }),
              ]
            },
          },
          {
            key: "operation",
            fn: function (ref) {
              var scope = ref.scope
              return [
                _c(
                  "div",
                  { staticClass: "flex-align-center" },
                  [
                    [
                      _c(
                        "div",
                        { staticClass: "more-handle-btn-box" },
                        [
                          _c(
                            "a-button",
                            {
                              attrs: { type: "link" },
                              on: {
                                click: function ($event) {
                                  return _vm.editVacation(scope.record)
                                },
                              },
                            },
                            [_vm._v(" 编辑 ")]
                          ),
                          _c(
                            "a-button",
                            {
                              staticClass: "delete",
                              attrs: { type: "link" },
                              on: {
                                click: function ($event) {
                                  return _vm.delet(scope.record)
                                },
                              },
                            },
                            [_vm._v(" 删除 ")]
                          ),
                        ],
                        1
                      ),
                    ],
                  ],
                  2
                ),
              ]
            },
          },
        ]),
      }),
      _c("PlanModal", {
        ref: "planModal",
        attrs: {
          "form-type": _vm.formType,
          "vacation-rule-id": _vm.vacationRuleId,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }