var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ShiftManagement", attrs: { id: "schedulingCalendar" } },
    [
      _c(
        "appForm",
        {
          attrs: { "to-list": _vm.formList, "search-placeholder": "请搜索" },
          on: { getSearchCallback: _vm.getSearchCallback },
        },
        [
          _c(
            "template",
            { slot: "customButton" },
            [
              _c(
                "a-button",
                {
                  attrs: { type: "primary", prefix: "add-one" },
                  on: { click: _vm.addScheduling },
                },
                [_vm._v(" 新增排班日历 ")]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("custom-table", {
        attrs: {
          id: "table",
          "row-key": "shiftSchedulingId",
          columns: _vm.columns,
          "data-source": _vm.dataSource,
          pagination: false,
        },
        on: { change: _vm.loadDataList },
        scopedSlots: _vm._u([
          {
            key: "shiftCycleType",
            fn: function (ref) {
              var scope = ref.scope
              return [
                _c("p", [
                  _vm._v(
                    _vm._s(
                      _vm._f("shiftCycleType")(scope.record.shiftCycleType)
                    )
                  ),
                ]),
              ]
            },
          },
          {
            key: "syncHolidayFlag",
            fn: function (ref) {
              var scope = ref.scope
              return [
                _c("p", [
                  _vm._v(_vm._s(scope.record.syncHolidayFlag ? "是" : "否")),
                ]),
              ]
            },
          },
          {
            key: "operation",
            fn: function (ref) {
              var scope = ref.scope
              return [
                _c(
                  "div",
                  { staticClass: "flex-align-center" },
                  [
                    [
                      _c(
                        "div",
                        { staticClass: "handle-btn-box" },
                        [
                          _c(
                            "a-button",
                            {
                              attrs: { type: "link" },
                              on: {
                                click: function ($event) {
                                  return _vm.editScheduling(scope.record)
                                },
                              },
                            },
                            [_vm._v("编辑")]
                          ),
                        ],
                        1
                      ),
                    ],
                    _c(
                      "a-popover",
                      { attrs: { trigger: "hover" } },
                      [
                        _c("template", { slot: "content" }, [
                          _c(
                            "div",
                            { staticClass: "more-handle-btn" },
                            [
                              _c(
                                "a-button",
                                {
                                  attrs: { type: "link" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.copyScheduling(scope.record)
                                    },
                                  },
                                },
                                [_vm._v("复制")]
                              ),
                              _c(
                                "a-button",
                                {
                                  staticClass: "delete",
                                  attrs: { type: "link" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.delet(scope.record)
                                    },
                                  },
                                },
                                [_vm._v("删除")]
                              ),
                            ],
                            1
                          ),
                        ]),
                        _c("div", { staticClass: "more-handle" }, [
                          _vm._v(" ··· "),
                        ]),
                      ],
                      2
                    ),
                  ],
                  2
                ),
              ]
            },
          },
        ]),
      }),
      _c("AddScheduling", {
        ref: "addScheduling",
        on: { ok: _vm.serchGetData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }