var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "map" },
    [
      _c("a-input-search", {
        staticStyle: { width: "200px" },
        attrs: { id: "tipinput", placeholder: _vm.searchPlaceholder },
        on: { search: _vm.getSearchCallback },
        model: {
          value: _vm.searchValue,
          callback: function ($$v) {
            _vm.searchValue = $$v
          },
          expression: "searchValue",
        },
      }),
      _c("div", { staticClass: "container", attrs: { id: "mapBox" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }