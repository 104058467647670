var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "attendancereminders" }, [
    _vm._m(0),
    _c("div", { staticClass: "attendancereminders-content" }, [
      _c("div", { staticClass: "attendancereminders-text" }, [
        _vm._m(1),
        _c(
          "div",
          { staticClass: "attendancereminders-text-content" },
          [
            _c("a-switch", {
              attrs: { size: "small" },
              on: {
                change: function ($event) {
                  return _vm.onChange($event, "auto_push_clock")
                },
              },
              model: {
                value: _vm.form.auto_push_clock,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "auto_push_clock", $$v)
                },
                expression: "form.auto_push_clock",
              },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "attendancereminders-text" }, [
        _c("div", { staticClass: "attendancereminders-text-title" }),
        _c(
          "div",
          { staticClass: "attendancereminders-text-content" },
          [
            _c(
              "a-checkbox",
              {
                attrs: { disabled: !_vm.form.auto_push_clock },
                on: {
                  change: function ($event) {
                    return _vm.onChange($event, "clock_in")
                  },
                },
                model: {
                  value: _vm.form.clock_in,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "clock_in", $$v)
                  },
                  expression: "form.clock_in",
                },
              },
              [_c("span", [_vm._v("上班打卡提醒 上班前10分钟内推送打卡提醒")])]
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "attendancereminders-text" }, [
        _c("div", { staticClass: "attendancereminders-text-title" }),
        _c(
          "div",
          { staticClass: "attendancereminders-text-content" },
          [
            _c(
              "a-checkbox",
              {
                attrs: { disabled: !_vm.form.auto_push_clock },
                on: {
                  change: function ($event) {
                    return _vm.onChange($event, "clock_out")
                  },
                },
                model: {
                  value: _vm.form.clock_out,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "clock_out", $$v)
                  },
                  expression: "form.clock_out",
                },
              },
              [_c("span", [_vm._v("下班打卡提醒 下班后系统自动推送打卡提醒")])]
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "attendancereminders-text" }, [
        _vm._m(2),
        _c(
          "div",
          { staticClass: "attendancereminders-text-content" },
          [
            _c("a-switch", {
              attrs: { size: "small" },
              on: {
                change: function ($event) {
                  return _vm.onChange($event, "clock_success_sound")
                },
              },
              model: {
                value: _vm.form.clock_success_sound,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "clock_success_sound", $$v)
                },
                expression: "form.clock_success_sound",
              },
            }),
          ],
          1
        ),
      ]),
    ]),
    _vm._m(3),
    _c("div", { staticClass: "attendancereminders-text" }, [
      _vm._m(4),
      _c(
        "div",
        { staticClass: "attendancereminders-text-content" },
        [
          _c("a-switch", {
            attrs: { size: "small" },
            on: {
              change: function ($event) {
                return _vm.onChange($event, "auto_push_mend")
              },
            },
            model: {
              value: _vm.form.auto_push_mend,
              callback: function ($$v) {
                _vm.$set(_vm.form, "auto_push_mend", $$v)
              },
              expression: "form.auto_push_mend",
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "attendancereminders-text" }, [
      _c("div", { staticClass: "attendancereminders-text-title" }),
      _c(
        "div",
        { staticClass: "attendancereminders-text-content" },
        [
          _c(
            "a-radio-group",
            {
              attrs: { disabled: !_vm.form.auto_push_mend },
              on: {
                change: function ($event) {
                  return _vm.onChange($event, "remind_cycle")
                },
              },
              model: {
                value: _vm.form.remind_cycle,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "remind_cycle", $$v)
                },
                expression: "form.remind_cycle",
              },
            },
            [
              _c(
                "a-radio",
                { style: _vm.radioStyle, attrs: { value: "day" } },
                [
                  _c("span", [_vm._v("每日")]),
                  _c("a-time-picker", {
                    staticStyle: { "margin-left": "10px" },
                    attrs: {
                      disabled:
                        _vm.form.remind_cycle !== "day" ||
                        !_vm.form.auto_push_mend,
                      format: "HH:mm",
                      "value-format": "HH:mm",
                    },
                    on: {
                      change: function ($event) {
                        return _vm.onChange($event, "day_mend_send_time")
                      },
                    },
                    model: {
                      value: _vm.form.day_mend_send_time,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "day_mend_send_time", $$v)
                      },
                      expression: "form.day_mend_send_time",
                    },
                  }),
                  _c("span", [_vm._v("仅提醒前一天班次内缺卡的人员")]),
                ],
                1
              ),
              _c(
                "a-radio",
                { style: _vm.radioStyle, attrs: { value: "month" } },
                [
                  _c("span", [_vm._v("每月")]),
                  _c(
                    "a-select",
                    {
                      staticStyle: { width: "170px", "margin-left": "10px" },
                      attrs: {
                        disabled:
                          _vm.form.remind_cycle !== "month" ||
                          !_vm.form.auto_push_mend,
                        placeholder: "请选择",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.onChange($event, "month_mend_send_day")
                        },
                      },
                      model: {
                        value: _vm.form.month_mend_send_day,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "month_mend_send_day", $$v)
                        },
                        expression: "form.month_mend_send_day",
                      },
                    },
                    _vm._l(_vm.missingpunch_alert_day_type, function (item) {
                      return _c(
                        "a-select-option",
                        { key: item.codeKey, attrs: { value: item.codeKey } },
                        [_vm._v(" " + _vm._s(item.content) + " ")]
                      )
                    }),
                    1
                  ),
                  _c("a-time-picker", {
                    staticStyle: { "margin-left": "10px" },
                    attrs: {
                      format: "HH:mm",
                      "value-format": "HH:mm",
                      disabled:
                        _vm.form.remind_cycle !== "month" ||
                        !_vm.form.auto_push_mend,
                    },
                    on: {
                      change: function ($event) {
                        return _vm.onChange($event, "month_mend_send_time")
                      },
                    },
                    model: {
                      value: _vm.form.month_mend_send_time,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "month_mend_send_time", $$v)
                      },
                      expression: "form.month_mend_send_time",
                    },
                  }),
                  _c("span", [_vm._v("仅提醒当前考勤周期内缺卡的人员")]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "attendancereminders-title" }, [
      _c("span", [_vm._v("打卡提醒设置")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "attendancereminders-text-title" }, [
      _c("span", [_vm._v("自动推送打卡提醒")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "attendancereminders-text-title" }, [
      _c("span", [_vm._v("打卡成功声音提醒")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "attendancereminders-title" }, [
      _c("span", [_vm._v("补卡提醒设置")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "attendancereminders-text-title" }, [
      _c("span", [_vm._v("自动推送补卡提醒")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }