var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-content beauty-scroll", attrs: { id: "rules" } },
    [
      _c(
        "a-tabs",
        { attrs: { "default-active-key": "1" }, on: { change: _vm.callback } },
        [
          _c(
            "a-tab-pane",
            { key: "1", attrs: { tab: "补卡" } },
            [_c("CardRule")],
            1
          ),
          _c(
            "a-tab-pane",
            { key: "2", attrs: { tab: "加班" } },
            [_c("ExtraRule")],
            1
          ),
          _c(
            "a-tab-pane",
            { key: "3", attrs: { tab: "外勤" } },
            [_c("FieldRule")],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }