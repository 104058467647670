var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card-rule", attrs: { id: "fieldModal" } },
    [
      _c(
        "div",
        { staticClass: "card-main" },
        [
          _c(
            "AlertTips",
            { on: { look: _vm.lookTips } },
            [
              _c("template", { slot: "content" }, [
                _c("p", [
                  _vm._v(
                    "1、外勤规则可控制员工申请外出、出差审批的时长单位 (如按小时提交)、时长计算方式 (如按班次计算)。"
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    "2、可设置多个不同规则，设置后，前往【考勤组】把不同规则关联到各自考勤组。"
                  ),
                ]),
              ]),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "addrulebtn" },
            [
              _c(
                "a-button",
                {
                  attrs: { type: "primary", prefix: "add-one" },
                  on: { click: _vm.addField },
                },
                [_vm._v(" 新增外勤规则 ")]
              ),
            ],
            1
          ),
          _c("custom-table", {
            attrs: {
              id: "fieldModalTable",
              columns: _vm.fieldTable,
              "data-source": _vm.dataSource,
              scroll: {
                y: _vm.tableHeight,
              },
              "tabs-height": 36,
              "row-key": "outsideRuleId",
              pagination: false,
            },
            scopedSlots: _vm._u([
              {
                key: "goOutRule",
                fn: function (ref) {
                  var scope = ref.scope
                  return [
                    _vm._v(
                      " " + _vm._s(_vm._f("unit")(scope.record.outsideUnit))
                    ),
                  ]
                },
              },
              {
                key: "evectionRule",
                fn: function (ref) {
                  var scope = ref.scope
                  return [
                    _vm._v(
                      " " + _vm._s(_vm._f("unit")(scope.record.travelUnit))
                    ),
                  ]
                },
              },
              {
                key: "operation",
                fn: function (ref) {
                  var scope = ref.scope
                  return [
                    _c("div", { staticClass: "flex-align-center" }, [
                      _c(
                        "div",
                        { staticClass: "more-handle-btn-box" },
                        [
                          _c(
                            "a-button",
                            {
                              attrs: { type: "link" },
                              on: {
                                click: function ($event) {
                                  return _vm.edit(scope.record)
                                },
                              },
                            },
                            [_vm._v(" 编辑 ")]
                          ),
                          _c(
                            "a-button",
                            {
                              staticClass: "delete",
                              attrs: { type: "link" },
                              on: {
                                click: function ($event) {
                                  return _vm.deleted(scope.record)
                                },
                              },
                            },
                            [_vm._v(" 删除 ")]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "Modal",
        {
          attrs: {
            closable: true,
            visible: _vm.fieldVisible,
            "mask-closable": false,
            size: "normal",
            title: _vm.type === "add" ? "新增外勤规则" : "编辑外勤规则",
          },
          on: { cancel: _vm.onClose, ok: _vm.handleDelShift },
        },
        [
          _c(
            "div",
            { staticClass: "field-content" },
            [
              _c(
                "a-form-model",
                {
                  ref: "fieldForm",
                  attrs: {
                    model: _vm.fieldForm,
                    rules: _vm.rules,
                    "label-col": _vm.labelCol,
                    "wrapper-col": _vm.wrapperCol,
                  },
                },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "规则名称", prop: "ruleName" } },
                    [
                      _c("a-input", {
                        attrs: {
                          type: "text",
                          placeholder: "比如产品部的外勤规则",
                        },
                        model: {
                          value: _vm.fieldForm.ruleName,
                          callback: function ($$v) {
                            _vm.$set(_vm.fieldForm, "ruleName", $$v)
                          },
                          expression: "fieldForm.ruleName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("BlockTitle", {
                    staticClass: "msg-title",
                    attrs: { title: "外出规则" },
                  }),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "申请时间单位", prop: "outsideUnit" } },
                    [
                      _c("a-select", {
                        attrs: {
                          "default-value": "小时",
                          options: _vm.travelUnits,
                          placeholder: "请选择外出申请时间单位",
                        },
                        model: {
                          value: _vm.fieldForm.outsideUnit,
                          callback: function ($$v) {
                            _vm.$set(_vm.fieldForm, "outsideUnit", $$v)
                          },
                          expression: "fieldForm.outsideUnit",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("BlockTitle", {
                    staticClass: "msg-title",
                    attrs: { title: "出差规则" },
                  }),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "申请时间单位", prop: "travelUnit" } },
                    [
                      _c("a-select", {
                        attrs: {
                          "default-value": "小时",
                          options: _vm.travelUnits,
                          placeholder: "请选择出差申请时间单位",
                        },
                        model: {
                          value: _vm.fieldForm.travelUnit,
                          callback: function ($$v) {
                            _vm.$set(_vm.fieldForm, "travelUnit", $$v)
                          },
                          expression: "fieldForm.travelUnit",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }