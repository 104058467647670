var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "extra-rule", attrs: { id: "extraworkModal" } },
    [
      _c(
        "div",
        { staticClass: "extra-main" },
        [
          _c(
            "AlertTips",
            { on: { look: _vm.lookTips } },
            [
              _c("template", { slot: "content" }, [
                _c("p", [
                  _vm._v(
                    "1、设置工作日、休息日和节假日的加班时长的计算规则和补偿规则。"
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    "2、可设置多个不同规则，设置后，前往【考勤组】把不同规则关联到各自考勤组。"
                  ),
                ]),
              ]),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "addrulebtn" },
            [
              _c(
                "a-button",
                {
                  attrs: { type: "primary", prefix: "add-one" },
                  on: { click: _vm.addExtrawork },
                },
                [_vm._v(" 新增加班规则 ")]
              ),
            ],
            1
          ),
          _c("custom-table", {
            attrs: {
              id: "extraworkModaltable",
              scroll: {
                y: _vm.tableHeight,
              },
              "tabs-height": 36,
              columns: _vm.extraWorkTable,
              "data-source": _vm.dataSource,
              "row-key": "overtimeRuleId",
              pagination: false,
            },
            scopedSlots: _vm._u([
              {
                key: "compensationWay",
                fn: function (ref) {
                  var scope = ref.scope
                  return [
                    _vm._v(" 工作日："),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm._f("overtimeConvertType")(
                            scope.record.workdayOvertimeConvertType
                          )
                        )
                      ),
                    ]),
                    _c("br"),
                    _vm._v(" 休息日："),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm._f("overtimeConvertType")(
                            scope.record.offdayOvertimeConvertType
                          )
                        )
                      ),
                    ]),
                    _c("br"),
                    _vm._v(" 法定节假日："),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm._f("overtimeConvertType")(
                            scope.record.holidayOvertimeConvertType
                          )
                        )
                      ),
                    ]),
                    _c("br"),
                  ]
                },
              },
              {
                key: "accountingWay",
                fn: function (ref) {
                  var scope = ref.scope
                  return [
                    _vm._v(" 工作日："),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm._f("overtimeCalcType")(
                            scope.record.workdayOvertimeCalcType
                          )
                        )
                      ),
                    ]),
                    _c("br"),
                    _vm._v(" 休息日："),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm._f("overtimeCalcType")(
                            scope.record.offdayOvertimeCalcType
                          )
                        )
                      ),
                    ]),
                    _c("br"),
                    _vm._v(" 法定节假日："),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm._f("overtimeCalcType")(
                            scope.record.holidayOvertimeCalcType
                          )
                        )
                      ),
                    ]),
                    _c("br"),
                  ]
                },
              },
              {
                key: "operation",
                fn: function (ref) {
                  var scope = ref.scope
                  return [
                    _c("div", { staticClass: "flex-align-center" }, [
                      _c(
                        "div",
                        { staticClass: "more-handle-btn-box" },
                        [
                          _c(
                            "a-button",
                            {
                              attrs: { type: "link" },
                              on: {
                                click: function ($event) {
                                  return _vm.edit(scope.record)
                                },
                              },
                            },
                            [_vm._v(" 编辑 ")]
                          ),
                          _c(
                            "a-button",
                            {
                              staticClass: "delete",
                              attrs: { type: "link" },
                              on: {
                                click: function ($event) {
                                  return _vm.deleted(scope.record)
                                },
                              },
                            },
                            [_vm._v(" 删除 ")]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "Drawer",
        {
          attrs: {
            visible: _vm.extraVisible,
            "is-mask": false,
            size: "large",
            title: _vm.type === "add" ? "新增加班规则" : "编辑加班规则",
          },
          on: { close: _vm.onClose, onSubmit: _vm.handleDelShift },
        },
        [
          _c(
            "div",
            {
              ref: "extraContent",
              staticClass: "extra-content",
              attrs: { id: "extra-content" },
            },
            [
              _c(
                "a-form-model",
                {
                  ref: "extraRef",
                  attrs: { model: _vm.form, rules: _vm.rules },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "rule-name",
                      staticStyle: { height: "auto" },
                    },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          ref: "name",
                          staticClass: "ant-form-item-label",
                          attrs: { label: "规则名称", prop: "ruleName" },
                        },
                        [
                          _c("a-input", {
                            attrs: { placeholder: "比如产品部的加班规则" },
                            model: {
                              value: _vm.form.ruleName,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "ruleName", $$v)
                              },
                              expression: "form.ruleName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "weekday" }, [
                    _c("div", { staticClass: "msg-title" }, [
                      _vm._v(" 工作日加班 "),
                    ]),
                    _c(
                      "div",
                      { staticClass: "name" },
                      [
                        _c("span", [_vm._v("补偿方案")]),
                        _c("a-select", {
                          attrs: { options: _vm.overtimeConvertType },
                          model: {
                            value: _vm.form.workdayOvertimeConvertType,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.form,
                                "workdayOvertimeConvertType",
                                $$v
                              )
                            },
                            expression: "form.workdayOvertimeConvertType",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "name" },
                      [
                        _c("span", [_vm._v("核算规则")]),
                        _c(
                          "a-select",
                          {
                            on: {
                              change: function ($event) {
                                return _vm.ruleChange(
                                  _vm.form.workdayOvertimeCalcType,
                                  "workdayOvertimeLimitFlag"
                                )
                              },
                            },
                            model: {
                              value: _vm.form.workdayOvertimeCalcType,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "workdayOvertimeCalcType",
                                  $$v
                                )
                              },
                              expression: "form.workdayOvertimeCalcType",
                            },
                          },
                          _vm._l(_vm.overtimeCalcType, function (item) {
                            return _c(
                              "a-select-option",
                              {
                                key: item.value,
                                attrs: { value: item.value, title: item.label },
                              },
                              [_vm._v(" " + _vm._s(item.label) + " ")]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c("hr", { staticClass: "partition" }),
                    _c(
                      "div",
                      { staticClass: "switch " },
                      [
                        _c("span", [_vm._v("加班时间限制")]),
                        _c("a-switch", {
                          attrs: {
                            size: "small",
                            "checked-children": "开",
                            "un-checked-children": "关",
                            checked:
                              _vm.form.workdayOvertimeLimitFlag === 1
                                ? true
                                : false,
                            disabled: _vm.form.workdayOvertimeCalcType === "3",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.onchange("workdayOvertimeLimitFlag")
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _c("div", { ref: "topLeft" }),
                    _c(
                      "div",
                      { staticClass: "msg-cont weekday-limit" },
                      [
                        _vm._v("最后一次下班"),
                        _c("CbNumber", {
                          staticClass: "number",
                          attrs: {
                            disabled: _vm.form.workdayOvertimeLimitFlag !== 1,
                            max: "720",
                            "input-width": "54",
                          },
                          model: {
                            value: _vm.form.workdayBreakMinutes,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "workdayBreakMinutes", $$v)
                            },
                            expression: "form.workdayBreakMinutes",
                          },
                        }),
                        _vm._v("分钟后开始计算加班，最长加班时长"),
                        _c("CbNumber", {
                          staticClass: "number",
                          attrs: {
                            disabled: _vm.form.workdayOvertimeLimitFlag !== 1,
                            max: "720",
                            "input-width": "54",
                          },
                          model: {
                            value: _vm.form.workdayOvertimeMaxMinutes,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.form,
                                "workdayOvertimeMaxMinutes",
                                $$v
                              )
                            },
                            expression: "form.workdayOvertimeMaxMinutes",
                          },
                        }),
                        _vm._v("分钟 "),
                        _c(
                          "a-tooltip",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.form.workdayOvertimeLimitFlag,
                                expression: "form.workdayOvertimeLimitFlag",
                              },
                            ],
                            attrs: {
                              placement: "topRight",
                              trigger: "hover",
                              "get-popup-container": function () {
                                return _vm.$refs.extraContent
                              },
                            },
                          },
                          [
                            _c("template", { slot: "title" }, [
                              _c("div", [
                                _vm._v("最长加班时长限制12小时，合计720分钟。"),
                              ]),
                            ]),
                            _c("a-icon", {
                              class: {
                                "icon-disabled":
                                  !_vm.form.workdayOvertimeLimitFlag,
                              },
                              attrs: {
                                type: "exclamation-circle",
                                theme: "filled",
                              },
                            }),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "weekday closed" },
                    [
                      _c("div", { staticClass: "msg-title" }, [
                        _vm._v(" 休息日加班 "),
                      ]),
                      _c(
                        "div",
                        { staticClass: "name" },
                        [
                          _c("span", [_vm._v("补偿方案")]),
                          _c("a-select", {
                            attrs: {
                              "default-value": "不计补偿",
                              options: _vm.overtimeConvertType,
                            },
                            model: {
                              value: _vm.form.offdayOvertimeConvertType,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "offdayOvertimeConvertType",
                                  $$v
                                )
                              },
                              expression: "form.offdayOvertimeConvertType",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "name" },
                        [
                          _c("span", [_vm._v("核算规则")]),
                          _c(
                            "a-select",
                            {
                              on: {
                                change: function ($event) {
                                  return _vm.ruleChange(
                                    _vm.form.offdayOvertimeCalcType,
                                    "offdayOvertimeLimitFlag"
                                  )
                                },
                              },
                              model: {
                                value: _vm.form.offdayOvertimeCalcType,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "offdayOvertimeCalcType",
                                    $$v
                                  )
                                },
                                expression: "form.offdayOvertimeCalcType",
                              },
                            },
                            _vm._l(_vm.overtimeCalcType, function (item) {
                              return _c(
                                "a-select-option",
                                {
                                  key: item.value,
                                  attrs: {
                                    value: item.value,
                                    title: item.label,
                                  },
                                },
                                [_vm._v(" " + _vm._s(item.label) + " ")]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.form.offdayOvertimeCalcType !== "2"
                        ? _c(
                            "div",
                            { staticClass: "time" },
                            [
                              _c(
                                "a-form-model-item",
                                {
                                  ref: "time",
                                  staticClass: "ant-form-item-label",
                                  attrs: {
                                    label: "打卡有效时段",
                                    prop: "offdayPunchStartTime",
                                  },
                                },
                                [
                                  _c("a-time-picker", {
                                    attrs: {
                                      "default-open-value": _vm.moment(
                                        "00:00",
                                        "HH:mm"
                                      ),
                                      "get-popup-container": function () {
                                        return _vm.$refs.extraContent
                                      },
                                      format: "HH:mm",
                                      "value-format": "HH:mm",
                                      placeholder: "请选择",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.valid("offdayPunchStartTime")
                                      },
                                    },
                                    model: {
                                      value: _vm.form.offdayPunchStartTime,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "offdayPunchStartTime",
                                          $$v
                                        )
                                      },
                                      expression: "form.offdayPunchStartTime",
                                    },
                                  }),
                                  _vm._v(" - "),
                                  _c("a-time-picker", {
                                    attrs: {
                                      "default-open-value": _vm.moment(
                                        "00:00",
                                        "HH:mm"
                                      ),
                                      "get-popup-container": function () {
                                        return _vm.$refs.extraContent
                                      },
                                      format: "HH:mm",
                                      "value-format": "HH:mm",
                                      placeholder: "请选择",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.valid("offdayPunchStartTime")
                                      },
                                    },
                                    model: {
                                      value: _vm.form.offdayPunchEndTime,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "offdayPunchEndTime",
                                          $$v
                                        )
                                      },
                                      expression: "form.offdayPunchEndTime",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("hr", { staticClass: "partition" }),
                      _c(
                        "div",
                        { staticClass: "switch " },
                        [
                          _c("span", [_vm._v("加班时间限制")]),
                          _c("a-switch", {
                            attrs: {
                              size: "small",
                              "checked-children": "开",
                              "un-checked-children": "关",
                              checked:
                                _vm.form.offdayOvertimeLimitFlag === 1
                                  ? true
                                  : false,
                              disabled: _vm.form.offdayOvertimeCalcType === "3",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.onchange("offdayOvertimeLimitFlag")
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "breaks" }),
                      _c("div", { staticClass: "latest" }, [
                        _c("span", [_vm._v("最晚加班时间")]),
                        _c(
                          "div",
                          { staticClass: "time-picker" },
                          [
                            _c("a-time-picker", {
                              attrs: {
                                "default-open-value": _vm.moment(
                                  "00:00",
                                  "HH:mm"
                                ),
                                "get-popup-container": function () {
                                  return _vm.$refs.extraContent
                                },
                                disabled:
                                  _vm.form.offdayOvertimeLimitFlag !== 1,
                                format: "HH:mm",
                                "value-format": "HH:mm",
                                placeholder: "请选择",
                              },
                              model: {
                                value: _vm.form.offdayLatestPunchTime,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "offdayLatestPunchTime",
                                    $$v
                                  )
                                },
                                expression: "form.offdayLatestPunchTime",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "breaks" }),
                      _vm._l(_vm.restTimes, function (item, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "not-counted" },
                          [
                            _c("div", { staticClass: "time" }, [
                              _c("span", [_vm._v("不计加班时段")]),
                              _c(
                                "div",
                                { staticClass: "time-picker" },
                                [
                                  _c("a-time-picker", {
                                    attrs: {
                                      "default-open-value": _vm.moment(
                                        "00:00",
                                        "HH:mm"
                                      ),
                                      "get-popup-container": function () {
                                        return _vm.$refs.extraContent
                                      },
                                      disabled:
                                        _vm.form.offdayOvertimeLimitFlag !== 1,
                                      format: "HH:mm",
                                      "value-format": "HH:mm",
                                      placeholder: "请选择",
                                    },
                                    model: {
                                      value: item.startTime,
                                      callback: function ($$v) {
                                        _vm.$set(item, "startTime", $$v)
                                      },
                                      expression: "item.startTime",
                                    },
                                  }),
                                  _vm._v(" - "),
                                  _c("a-time-picker", {
                                    attrs: {
                                      "default-open-value": _vm.moment(
                                        "00:00",
                                        "HH:mm"
                                      ),
                                      "get-popup-container": function () {
                                        return _vm.$refs.extraContent
                                      },
                                      disabled:
                                        _vm.form.offdayOvertimeLimitFlag !== 1,
                                      format: "HH:mm",
                                      "value-format": "HH:mm",
                                      placeholder: "请选择",
                                    },
                                    model: {
                                      value: item.endTime,
                                      callback: function ($$v) {
                                        _vm.$set(item, "endTime", $$v)
                                      },
                                      expression: "item.endTime",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _c(
                              "div",
                              [
                                index + 1 === 1
                                  ? _c("a-button", {
                                      staticClass: "addBtn",
                                      attrs: {
                                        type: "iconBtn",
                                        icon: "add-one",
                                        disabled:
                                          _vm.form.offdayOvertimeLimitFlag !==
                                          1,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.restTimes.push({
                                            overtimeType: "B",
                                          })
                                        },
                                      },
                                    })
                                  : _c("a-button", {
                                      staticClass: "delete",
                                      attrs: {
                                        type: "iconBtn",
                                        icon: "close-small",
                                        disabled:
                                          _vm.form.offdayOvertimeLimitFlag !==
                                          1,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.del(index, "restTimes")
                                        },
                                      },
                                    }),
                              ],
                              1
                            ),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                  _c(
                    "div",
                    { staticClass: "weekday rest" },
                    [
                      _c("div", { staticClass: "msg-title" }, [
                        _vm._v(" 节假日加班 "),
                      ]),
                      _c(
                        "div",
                        { staticClass: "name" },
                        [
                          _c("span", [_vm._v("补偿方案")]),
                          _c("a-select", {
                            attrs: {
                              "default-value": "不计补偿",
                              options: _vm.overtimeConvertType,
                            },
                            model: {
                              value: _vm.form.holidayOvertimeConvertType,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "holidayOvertimeConvertType",
                                  $$v
                                )
                              },
                              expression: "form.holidayOvertimeConvertType",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "name" },
                        [
                          _c("span", [_vm._v("核算规则")]),
                          _c(
                            "a-select",
                            {
                              on: {
                                change: function ($event) {
                                  return _vm.ruleChange(
                                    _vm.form.holidayOvertimeCalcType,
                                    "holidayOvertimeLimitFlag"
                                  )
                                },
                              },
                              model: {
                                value: _vm.form.holidayOvertimeCalcType,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "holidayOvertimeCalcType",
                                    $$v
                                  )
                                },
                                expression: "form.holidayOvertimeCalcType",
                              },
                            },
                            _vm._l(_vm.overtimeCalcType, function (item) {
                              return _c(
                                "a-select-option",
                                {
                                  key: item.value,
                                  attrs: {
                                    value: item.value,
                                    title: item.label,
                                  },
                                },
                                [_vm._v(" " + _vm._s(item.label) + " ")]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.form.holidayOvertimeCalcType !== "2"
                        ? _c(
                            "div",
                            { staticClass: "time" },
                            [
                              _c(
                                "a-form-model-item",
                                {
                                  ref: "holidayPunchStartTime",
                                  staticClass: "ant-form-item-label",
                                  attrs: {
                                    label: "打卡有效时段",
                                    prop: "holidayPunchStartTime",
                                  },
                                },
                                [
                                  _c("a-time-picker", {
                                    attrs: {
                                      "default-open-value": _vm.moment(
                                        "00:00",
                                        "HH:mm"
                                      ),
                                      "get-popup-container": function () {
                                        return _vm.$refs.extraContent
                                      },
                                      format: "HH:mm",
                                      "value-format": "HH:mm",
                                      placeholder: "请选择",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.valid(
                                          "holidayPunchStartTime"
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.form.holidayPunchStartTime,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "holidayPunchStartTime",
                                          $$v
                                        )
                                      },
                                      expression: "form.holidayPunchStartTime",
                                    },
                                  }),
                                  _vm._v(" - "),
                                  _c("a-time-picker", {
                                    attrs: {
                                      "default-open-value": _vm.moment(
                                        "00:00",
                                        "HH:mm"
                                      ),
                                      "get-popup-container": function () {
                                        return _vm.$refs.extraContent
                                      },
                                      format: "HH:mm",
                                      "value-format": "HH:mm",
                                      placeholder: "请选择",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.valid(
                                          "holidayPunchStartTime"
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.form.holidayPunchEndTime,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "holidayPunchEndTime",
                                          $$v
                                        )
                                      },
                                      expression: "form.holidayPunchEndTime",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("hr", { staticClass: "partition" }),
                      _c(
                        "div",
                        { staticClass: "switch " },
                        [
                          _c("span", [_vm._v("加班时间限制")]),
                          _c("a-switch", {
                            attrs: {
                              size: "small",
                              "checked-children": "开",
                              "un-checked-children": "关",
                              checked:
                                _vm.form.holidayOvertimeLimitFlag === 1
                                  ? true
                                  : false,
                              disabled:
                                _vm.form.holidayOvertimeCalcType === "3",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.onchange("holidayOvertimeLimitFlag")
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "breaks" }),
                      _c("div", { staticClass: "latest" }, [
                        _c("span", [_vm._v("最晚加班时间")]),
                        _c(
                          "div",
                          { staticClass: "time-picker" },
                          [
                            _c("a-time-picker", {
                              attrs: {
                                "default-open-value": _vm.moment(
                                  "00:00",
                                  "HH:mm"
                                ),
                                "get-popup-container": function () {
                                  return _vm.$refs.extraContent
                                },
                                format: "HH:mm",
                                disabled:
                                  _vm.form.holidayOvertimeLimitFlag !== 1,
                                "value-format": "HH:mm",
                                placeholder: "请选择",
                              },
                              model: {
                                value: _vm.form.holidayLatestPunchTime,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "holidayLatestPunchTime",
                                    $$v
                                  )
                                },
                                expression: "form.holidayLatestPunchTime",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._l(_vm.holidayTimes, function (item, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "not-counted" },
                          [
                            _c("div", { staticClass: "time" }, [
                              _c("span", [_vm._v("不计加班时段")]),
                              _c(
                                "div",
                                { staticClass: "time-picker" },
                                [
                                  _c("a-time-picker", {
                                    attrs: {
                                      "default-open-value": _vm.moment(
                                        "00:00",
                                        "HH:mm"
                                      ),
                                      "get-popup-container": function () {
                                        return _vm.$refs.extraContent
                                      },
                                      disabled:
                                        _vm.form.holidayOvertimeLimitFlag !== 1,
                                      format: "HH:mm",
                                      "value-format": "HH:mm",
                                      placeholder: "请选择",
                                    },
                                    model: {
                                      value: item.startTime,
                                      callback: function ($$v) {
                                        _vm.$set(item, "startTime", $$v)
                                      },
                                      expression: "item.startTime",
                                    },
                                  }),
                                  _vm._v(" - "),
                                  _c("a-time-picker", {
                                    attrs: {
                                      "default-open-value": _vm.moment(
                                        "00:00",
                                        "HH:mm"
                                      ),
                                      "get-popup-container": function () {
                                        return _vm.$refs.extraContent
                                      },
                                      disabled:
                                        _vm.form.holidayOvertimeLimitFlag !== 1,
                                      format: "HH:mm",
                                      "value-format": "HH:mm",
                                      placeholder: "请选择",
                                    },
                                    model: {
                                      value: item.endTime,
                                      callback: function ($$v) {
                                        _vm.$set(item, "endTime", $$v)
                                      },
                                      expression: "item.endTime",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _c(
                              "div",
                              [
                                index + 1 === 1
                                  ? _c("a-button", {
                                      staticClass: "addBtn",
                                      attrs: {
                                        type: "iconBtn",
                                        icon: "add-one",
                                        disabled:
                                          _vm.form.holidayOvertimeLimitFlag !==
                                          1,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.holidayTimes.push({
                                            overtimeType: "C",
                                          })
                                        },
                                      },
                                    })
                                  : _c("a-button", {
                                      staticClass: "delete",
                                      attrs: {
                                        type: "iconBtn",
                                        icon: "close-small",
                                        disabled:
                                          _vm.form.holidayOvertimeLimitFlag !==
                                          1,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.del(index, "holidayTimes")
                                        },
                                      },
                                    }),
                              ],
                              1
                            ),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                  _c("div", { staticClass: "msg-title" }, [
                    _vm._v(" 加班最小时长 "),
                  ]),
                  _c(
                    "div",
                    { staticClass: "msg-cont" },
                    [
                      _vm._v(" 加班最小时长"),
                      _c("CbNumber", {
                        staticClass: "cbnumber",
                        attrs: { max: "720", "input-width": "54" },
                        model: {
                          value: _vm.form.overtimeMinMinutes,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "overtimeMinMinutes", $$v)
                          },
                          expression: "form.overtimeMinMinutes",
                        },
                      }),
                      _vm._v("分钟，加班达到该时长，才能算加班，否则不计加班 "),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "msg-title" }, [
                    _vm._v(" 加班时长计算单位 "),
                  ]),
                  _c(
                    "div",
                    { staticClass: "msg-cont" },
                    [
                      _vm._v(" 加班时长计算单位 "),
                      _c("a-select", {
                        staticClass: "select",
                        attrs: { options: _vm.overtimeUnit },
                        model: {
                          value: _vm.form.overtimeUnit,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "overtimeUnit", $$v)
                          },
                          expression: "form.overtimeUnit",
                        },
                      }),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.form.overtimeUnit === "1",
                              expression: "form.overtimeUnit==='1'",
                            },
                          ],
                        },
                        [_vm._v("员工加班时长，以分钟为单位计算，不足的不计算")]
                      ),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.form.overtimeUnit === "2",
                              expression: "form.overtimeUnit==='2'",
                            },
                          ],
                        },
                        [
                          _vm._v(
                            "员工加班时长，以半小时为单位计算，不足的不计算"
                          ),
                          _c("span", { staticClass: "weekday-rest" }, [
                            _vm._v("（例如：加班1.62小时=加班1.5小时）"),
                          ]),
                        ]
                      ),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.form.overtimeUnit === "3",
                              expression: "form.overtimeUnit==='3'",
                            },
                          ],
                        },
                        [
                          _vm._v(
                            "员工加班时长，以小时为单位计算，不足的不计算"
                          ),
                          _c("span", { staticClass: "weekday-rest" }, [
                            _vm._v("（例如：加班1.62小时=加班1小时）"),
                          ]),
                        ]
                      ),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.form.overtimeUnit === "4",
                              expression: "form.overtimeUnit==='4'",
                            },
                          ],
                        },
                        [
                          _vm._v(
                            "员工加班时长，以半天为单位计算，不足的不计算"
                          ),
                          _c("span", { staticClass: "weekday-rest" }, [
                            _vm._v("（例如：加班0.85天=加班0.5天）"),
                          ]),
                        ]
                      ),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.form.overtimeUnit === "5",
                              expression: "form.overtimeUnit==='5'",
                            },
                          ],
                        },
                        [
                          _vm._v("员工加班时长，以天为单位计算，不足的不计算"),
                          _c("span", { staticClass: "weekday-rest" }, [
                            _vm._v("（例如：加班0.85天=加班0天）"),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "msg-title" }, [
                    _vm._v(" 加班时长统计等在员工端展示 "),
                  ]),
                  _c(
                    "div",
                    { staticClass: "msg-content" },
                    [
                      _vm._v(" 是否展示 "),
                      _c("a-switch", {
                        attrs: {
                          checked: _vm.form.appletShowFlag ? true : false,
                        },
                        on: {
                          change: function (check) {
                            return (_vm.form.appletShowFlag = check ? 1 : 0)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }