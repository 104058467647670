var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "user-setting-panel" },
    [
      _c(
        "layout",
        {
          ref: "layoutMenu",
          staticClass: "left-menu-panel",
          attrs: { "menu-data-list": _vm.menuDataList },
          on: { "menu-select-change": _vm.menuSelectChange },
          scopedSlots: _vm._u([
            {
              key: "leftTop",
              fn: function () {
                return [_c("div", { staticClass: "left-top-p" })]
              },
              proxy: true,
            },
          ]),
        },
        [_c(_vm.curComponentName, { tag: "component" })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }