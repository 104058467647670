var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "HolidayType beauty-scroll", attrs: { id: "HolidayType" } },
    [
      _c(
        "AlertTips",
        [
          _c("template", { slot: "content" }, [
            _c("p", [
              _vm._v(
                "1.您可以新增/启用更多假期类型。设置后，可以到【假期】→【假期方案】 为员工设置不同假期规则。"
              ),
            ]),
            _c("p", [_vm._v("2.员工请假先扣除带薪假期余额，再计算请假扣款。")]),
          ]),
        ],
        2
      ),
      _c(
        "appForm",
        {
          attrs: { "to-list": _vm.formList, "search-placeholder": "请搜索" },
          on: { getSearchCallback: _vm.getSearchCallback },
        },
        [
          _c(
            "template",
            { slot: "customButton" },
            [
              _c(
                "a-button",
                {
                  attrs: { type: "primary", prefix: "add-one" },
                  on: { click: _vm.addType },
                },
                [_vm._v(" 新增假期类型 ")]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("custom-table", {
        attrs: {
          id: "table",
          columns: _vm.columns,
          "data-source": _vm.dataSource,
          "row-key": "vacationTypeId",
          pagination: false,
        },
        on: { change: _vm.loadDataList },
        scopedSlots: _vm._u([
          {
            key: "textColor",
            fn: function (ref) {
              var scope = ref.scope
              return [
                _c("div", {
                  staticClass: "color-div",
                  style: _vm.getcolor(scope.record.textColor),
                }),
              ]
            },
          },
          {
            key: "switchStatus",
            fn: function (ref) {
              var scope = ref.scope
              return _c("span", {}, [
                _c(
                  "div",
                  [
                    _c("a-switch", {
                      attrs: {
                        checked: scope.record.status ? true : false,
                        size: "small",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.onChange(scope.record)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ])
            },
          },
          {
            key: "vacationTypeUnit",
            fn: function (ref) {
              var scope = ref.scope
              return [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      scope.record.vacationTypeUnit === "D" ? "天" : "小时"
                    )
                  ),
                ]),
              ]
            },
          },
          {
            key: "operation",
            fn: function (ref) {
              var scope = ref.scope
              return [
                _c(
                  "div",
                  { staticClass: "flex-align-center" },
                  [
                    [
                      _c(
                        "div",
                        { staticClass: "more-handle-btn-box" },
                        [
                          _c(
                            "a-button",
                            {
                              attrs: { type: "link" },
                              on: {
                                click: function ($event) {
                                  return _vm.editType(scope.record)
                                },
                              },
                            },
                            [_vm._v(" 设置 ")]
                          ),
                          _c(
                            "a-button",
                            {
                              staticClass: "delete",
                              attrs: { type: "link" },
                              on: {
                                click: function ($event) {
                                  return _vm.delet(scope.record)
                                },
                              },
                            },
                            [_vm._v(" 删除 ")]
                          ),
                        ],
                        1
                      ),
                    ],
                  ],
                  2
                ),
              ]
            },
          },
        ]),
      }),
      _c("TypeModal", {
        ref: "typeModal",
        attrs: { "form-type": _vm.formType },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }