var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "statisticalperiod" }, [
    _c("div", { staticClass: "tips" }, [
      _c("p", [
        _vm._v(
          "系统将按薪酬计算周期统计考勤结果，如需更改，前往【薪酬】-【设置】-【计薪周期】设置，且历史月份不受影响。"
        ),
      ]),
      _c("p", [
        _vm._v("考勤统计周期：" + _vm._s(_vm.tips) + " "),
        _c("span", { staticClass: "set", on: { click: _vm.goTo } }, [
          _vm._v("前往设置"),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }